<template>
	<div v-if="!hideLoginForm" id="login" class="input-heading" :class="playerState.loggedIn ? 'loggedin' : ''">
		<div class="login-status" v-if="playerState.loggedIn && !isMobile">
			<span>{{ playerState.displayName }}</span>
			<button class="btn" type="button" :title="languageStrings.logout" @click="showLogoutDialog()">
				{{ languageStrings.logout }}
			</button>
			<span v-if="playerState.permissionsSuspended" class="warn">{{ languageErrorStrings.suspended }}</span>
		</div>
		<div v-if="serverBusy" id="loading">
			<span class="loading-icon"></span>
			<span class="loading-message">{{ busyText }}</span>
		</div>
		<form class="input-section encore-background" v-if="!playerState.loggedIn">
			<img
				v-if="countryCode == 'MX'"
				class="logon-logo-sm"
				:src="`${locationOrigin}/styleOverrides/encore_online.png`"
				alt="encoreonline.mx"
			/>
			<h2>{{ languageStrings.pleaseLogIn }}</h2>
			<label for="phoneNumber">{{ languageStrings.playersPhoneNumber }}</label>
			<input
				type="tel"
				pattern="[0-9]+"
				id="phoneNumber"
				:name="languageStrings.playersPhoneNumber"
				@keydown.enter="login()"
				v-model="phoneNumber"
				:placeholder="languageStrings.playersPhoneNumber"
				autocomplete="off"
				:maxlength="allowPhoneExtensionDigits ? '' : phoneNumberLength"
				class="curved-border"
			/>
			<label for="password">{{ languageStrings.password }}</label>
			<div class="password-container">
				<input
					:type="unhidePassword ? 'text' : 'password'"
					id="password"
					:name="languageStrings.password"
					@keydown.enter="login()"
					v-model="password"
					:placeholder="languageStrings.password"
					autocomplete="new-password"
					class="curved-border"
				/>
				<span
					@click="unhidePassword = unhidePassword ? false : true"
					:class="unhidePassword ? 'hidden' : ''"
					:title="unhidePassword ? languageStrings.hidePassword : languageStrings.showPassword"
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="16"
						height="16"
						fill="currentColor"
						class="eye"
						viewBox="0 0 16 16"
					>
						<path
							d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"
						/>
						<path
							d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"
						/>
					</svg>
				</span>
			</div>
			<span v-if="capsLockOn" style="color: #ff0; text-align: center; font-weight: 700">{{
				languageStrings.capLockIsOn
			}}</span>
			<div class="encore-style-button-container">
				<button
					class="btn encore-style-button curved-border"
					type="button"
					:title="languageStrings.loginButton"
					@click="login()"
				>
					{{ languageStrings.loginButton }}
				</button>
			</div>
			<router-link
				v-if="countryCode === 'MX' && systemSettings.businessCountry !== 'US'"
				to="/extendedRegisterView"
				:title="languageStrings.register"
				class="new-user-link"
				>{{ languageStrings.register }}</router-link
			>
			<router-link
				v-if="countryCode !== 'MX' && systemSettings.businessCountry === 'US'"
				to="/multiComponentRegisterView"
				:title="languageStrings.register"
				class="new-user-link"
				>{{ languageStrings.register }}</router-link
			>
			<span :title="languageStrings.forgotPassword" id="lost-pw-modal" @click="lostPasswordModal()">{{
				languageStrings.forgotPassword
			}}</span>
		</form>
	</div>
	<dialog id="logout-dialog">
		<div>
			<p>
				{{ `${this.languageErrorStrings.logoutConfirm}, ${this.playerState.displayName}` }}
			</p>
			<div id="btn-container">
				<button id="confirmButton" class="btn" value="default" @click="logout()">Ok</button>
				<button id="cancelButton" class="btn" value="cancel" formmethod="dialog" @click="closeModal()">
					{{ languageStrings.cancel }}
				</button>
			</div>
		</div>
	</dialog>
	<transition name="fade">
		<component
			v-bind:is="currentModal"
			:capsLockOn="capsLockOn"
			:serverRules="serverRules"
			:isMobile="isMobile"
			:languageStrings="languageStrings"
			:languageErrorStrings="languageErrorStrings"
		/>
	</transition>
</template>

<script>
import router from "@/router";
import { onBeforeUnmount } from "vue";
import PasswordReset from "@/components/PasswordReset";
import sharedScripts from "@/dependencies/sharedScripts";
import CurrencyTool from "@/dependencies/currencyTool";

export default {
	name: "Login",
	components: {
		PasswordReset,
	},
	props: {
		playerState: Object,
		capsLockOn: Boolean,
		serverRules: Object,
		hideLoginForm: Boolean,
		isMobile: Boolean,
		casinoList: Array,
		systemSettings: Object,
		languageStrings: Object,
		languageErrorStrings: Object,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			allowPhoneExtensionDigits: false,
			unhidePassword: false,
			currentModal: null,
			phoneNumber: "",
			password: "",
			selectedCountry: {},
			systemSettingsLoaded: false,
			serverBusy: false,
			busyText: "",
			inputDialog: null,
			locationOrigin: "",
		};
	},
	watch: {
		playerState: {
			handler() {
				this.phoneNumber = this.playerState?.phoneNumber;
			},
			deep: true,
		},
	},
	created() {
		this.eventBus.on("countrySelected", (payload) => {
			this.selectedCountry = payload;
		});
		this.eventBus.on("serverSettingsLoaded", (payload) => {
			this.allowPhoneExtensionDigits = payload.allowPhoneExtensionDigits;
		});
		this.eventBus.on("returnFromResetPW", () => {
			this.resetModal();
		});
		this.eventBus.on("captchaDismissed", () => {
			this.resetModal();
		});
		this.eventBus.on("forceLogout", () => {
			this.logout(true);
		});
		this.eventBus.on("regularLogout", () => {
			this.showLogoutDialog();
		});
		onBeforeUnmount(() => {
			this.eventBus.off("countrySelected");
			this.eventBus.off("serverSettingsLoaded");
			this.eventBus.off("returnFromResetPW");
			this.eventBus.off("captchaDismissed");
			this.eventBus.off("forceLogout");
			this.eventBus.off("regularLogout");
		});
	},
	mounted() {
		this.locationOrigin = window.location.origin;
		this.inputDialog = document.getElementById("logout-dialog");
	},
	methods: {
		resetModal() {
			this.currentModal = null;
			this.password = "";
			this.phoneNumber = "";
			let loginElem = document.getElementById("login");
			if (loginElem && loginElem.classList.contains("hide")) loginElem.classList.remove("hide");
		},
		lostPasswordModal() {
			let loginElem = document.getElementById("login");
			loginElem.classList.add("hide");
			this.currentModal = "PasswordReset";
		},
		async getCurrentBalances(account) {
			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${account.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let requestUrl = new URL("/api/v1/funds", this.rabbitsfootHostUrl);

			let request = new Request(requestUrl.toString(), {
				method: "GET",
				headers: headerObj,
			});

			try {
				const response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout === true) this.eventBus.emit("forceLogout");
					return false;
				}

				let dataJson = await response.json();

				dataJson.forEach((balance) => {
					let matchedByCasinoId = this.casinoList.filter((casino) => balance.casinoId == casino.id)[0];
					// Insert an instance of the currency tool class specific to each casino's in-game currency rules.
					balance.currencyTool = new CurrencyTool(matchedByCasinoId.currencyInfo, this.localeString);
				});

				return dataJson;
			} catch (e) {
				console.error(e);
			}
		},
		async login() {
			let hasErrors = false;
			this.serverBusy = true;
			this.busyText = this.languageStrings.checkingCradentials;

			// if (this?.phoneNumber?.match(/[^0-9]/)) {
			// 	this.status.message = this.languageErrorStrings.numbersOnly;
			// 	this.status.ok = false;
			// 	this.eventBus.emit("updateStatus", this.status);
			// 	hasErrors = true;
			// }

			if (!this.phoneNumber || !this.password) {
				this.status.message = this.languageErrorStrings.provideValidPhoneAndPassword;
				this.status.ok = false;
				this.eventBus.emit("updateStatus", this.status);
				hasErrors = true;
			}

			if (hasErrors) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let body = {
				phoneNumber: this.phoneNumber.toString(), //.replace(/\D/g, ""),
				password: this.password,
			};

			let requestUrl = new URL("/api/v1/authentication/login", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let request = new Request(requestUrl.toString(), {
				method: "POST",
				body: JSON.stringify(body),
				headers: headerObj,
			});

			try {
				const response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.serverBusy = false;
					this.busyText = "";
					this.eventBus.emit("updateStatus", fetchStatus);
					return;
				}

				let dataJson = await response.json();

				dataJson.loggedIn = true;
				dataJson.permissionsSuspended = dataJson.permissions.arePermissionsSuspended;
				this.password = "";
				this.unhidePassword = false;

				dataJson.casinoBalances = (await this.getCurrentBalances(dataJson)) || {};

				this.serverBusy = false;
				this.busyText = "";

				this.eventBus.emit("updateCasinosIncludingDeleted", this.status.ok);
				if (!fetchStatus.message == 200) this.eventBus.emit("updateStatus", fetchStatus);
				this.eventBus.emit("updatePlayerState", dataJson);
				this.eventBus.emit("updateUserProfile");
				this.eventBus.emit("listDocumentAcknowlegement");
			} catch (e) {
				console.error(e);
				this.serverBusy = false;
				this.busyText = "";
				this.status.ok = false;
				this.status.message = e;
				this.eventBus.emit("updateStatus", this.status);
			}
		},
		showLogoutDialog() {
			this.inputDialog.showModal();
		},
		closeModal() {
			this.inputDialog.close();
		},
		logout(forced = false) {
			let playerState = {};
			this.password = "";
			this.unhidePassword = false;
			this.phoneNumber = "";
			this.eventBus.emit("updatePlayerState", playerState);
			this.eventBus.emit("loggedOut");
			this.eventBus.emit("toggleNavBar", true);
			this.eventBus.emit("closeChangeDisplayNameModal");
			this.eventBus.emit("closeDocumentsDisplay");
			this.closeModal();
			router.push("/");
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#loading {
	display: grid;
	align-content: center;
	position: fixed;
	width: 100%;
	height: 100%;
	text-align: center;
	font-weight: bold;
	background-color: rgb(0 0 0 / 70%);
	backdrop-filter: blur(6px);
	z-index: 100;
}

.loading-message {
	text-align: center;
	display: block;
	margin: 15px;
}

h3 {
	text-align: center;
}

#login {
	display: grid;
	height: 100%;
	align-content: center;
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	width: 100%;
	z-index: 100;
	background-color: rgb(0 0 0 / 50%);
	backdrop-filter: blur(3px);
	-webkit-backdrop-filter: blur(3px);
}

#login.loggedin {
	height: auto;
	z-index: 150;
}

#logout-dialog {
	background-color: #000;
	color: #fff;
	border-radius: 12px;
	border-width: 1px;
}

::backdrop {
	background-color: #000;
	backdrop-filter: blur(8px);
	opacity: 0.75;
}

#btn-container {
	display: flex;
}

.hide {
	display: none;
}

.input-section {
	display: flex;
	flex-direction: column;
	position: relative;
	top: 0;
	width: 60%;
	max-width: 30em;
	padding: 0 30px 30px;
	margin: auto;
	background: #121832;
	border-radius: 12px;
	box-shadow: inset -1px -1px 15px 1px rgb(13 28 37 / 50%);
}

input {
	height: 3.5vh;
	color: #6305c9;
}

.login-status {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	position: fixed;
	top: 0;
	right: 0;
	padding: 5px 10px;
	font-size: 0.625em;
	background-color: #32373f;
	border-radius: 0 0 0 0.4em;
	border: 1px #5b88c0 solid;
}

.input-heading form {
	display: flex;
	flex-direction: column;
}

.login-status button {
	user-select: none;
	margin: 8px auto;
	padding: 5px;
}

label[for="casinoId"] {
	text-align: center;
	text-transform: uppercase;
	text-shadow: -1px -1px 0px #000, 1px -1px 0px #000, -1px 1px 0px #000, 1px 1px 0px #000;
}

/* .submit-login {
	padding: 5px 10px;
	margin: 10px auto;
} */

.new-user-link,
#lost-pw-modal {
	text-align: center;
	color: white;
	padding: 15px 15px 0;
	text-decoration: #4542ff underline;
	cursor: pointer;
}
.new-user-link:hover,
#lost-pw-modal:hover {
	text-decoration: #fff underline;
}

.password-container span {
	right: 10px;
	top: 12px;
	color: #6305c9;
}

.encore-style-button-container {
	margin-top: 2vh;
}

.encore-style-button-container:hover {
	transform: scale(1.05);
	width: auto;
}

.encore-style-button:hover {
	transform: none;
}

@media (min-width: 768px) {
	#login {
		left: unset;
		z-index: 100;
	}

	#logout-dialog {
		margin: auto;
	}
}

@media (orientation: landscape) and (max-height: 500px) {
	div#login {
		height: calc(100vh - 140px);
		overflow: hidden auto;
		top: 80px;
	}

	.input-section {
		margin: 80px auto 0;
		padding: 30px;
	}
}
</style>
