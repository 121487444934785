<template>
	<div>
		<div v-if="transactions.length > 0">
			<table class="table transactions encore-background">
				<!-- <tr>
					<th>{{ languageStrings.casinoId }}</th>
					<td>{{ item.casinoId }}</td>
				</tr> -->
				<thead>
					<tr class="header-row">
						<th>{{ languageStrings.fromPlayerCash }}</th>
          				<th>{{ languageStrings.initiationDate }}</th>
					<!-- <td>{{ systemCurrencyTool.formatCurrency(item.fromPlayerCashCOC, systemCurrencyTool.displayType.minorOrFull) }}</td> -->
					</tr>
				</thead>
				<tbody v-for="(item, index) in transactions" :key="index" @click="openDetails(index)">
					<tr :id="index">
          				<td v-if="item.fromPlayerCashCOC >=0" >{{ systemCurrencyTool.formatCurrency(item.fromPlayerCashCOC, systemCurrencyTool.displayType.minorOrFull) }}</td>
						<td v-else class="negative-value">{{systemCurrencyTool.formatCurrency(item.fromPlayerCashCOC, systemCurrencyTool.displayType.minorOrFull) }}</td>
          				<td v-if="item.initiationDate" class="nowrap-text">{{ new Date(item.initiationDate).toLocaleTimeString(this.localeString, dateOptions) }}</td>
						<td v-else></td>
					</tr>
					<tr class="panel">
          				<td colspan="2">
							<thead>
								<th class="curved-border">{{ languageStrings.status }}</th>
								<th>{{ languageStrings.offerExpirationDate }}</th>
								<th>{{ languageStrings.provider}}</th>
								<th class="curved-border">{{ languageStrings.statusAdditionalInfo }}</th>
							</thead>
							<td class="expandable-data">{{ item.status }}</td>
							<td v-if="item.offerExpirationDate" class="expandable-data">{{ new Date(item.offerExpirationDate).toLocaleTimeString(this.localeString, dateOptions) }}</td>
							<td v-else></td>
							<td class="expandable-data">{{ item.provider }}</td> 
          				</td>
        			</tr>
				</tbody>
			
				<!-- <tr>
					<th>{{ languageStrings.initiationDate }}</th>
					<td v-if="item.initiationDate">{{ new Date(item.initiationDate).toLocaleTimeString(this.localeString, dateOptions) }}</td>
					<td v-else></td>
				</tr>
				<tr>
					<th>{{ languageStrings.offerExpirationDate }}</th>
					<td v-if="item.offerExpirationDate">{{ new Date(item.offerExpirationDate).toLocaleTimeString(this.localeString, dateOptions) }}</td>
					<td v-else></td>
				</tr>
				<tr>
					<th>{{ languageStrings.provider }}</th>
					<td>{{ item.provider }}</td>
				</tr>
				<tr>
					<th>{{ languageStrings.statusAdditionalInfo }}</th>
					<td>{{ item.statusAdditionalInfo }}</td>
				</tr> -->
			</table>
		</div>
		<h2 v-else>{{ languageStrings.noTransactionsFound }}</h2>
	</div>
</template>

<script >
import sharedScripts from '../dependencies/sharedScripts';

export default {
	name: "TransactionsTableMobile",
	props: {
		systemCurrencyTool: Object,
		systemSettings: Object,
		transactions: Array,
		languageStrings: Object,
	},

	
	methods: {	
		
		openDetails(index) {
			document.getElementById(index).addEventListener("click", this.open(index));
		},

		open(index) {
			var currentRow = document.getElementById(index);
			sharedScripts.openRowDetails(currentRow);
		}
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

tr.header-row {
	position: sticky;
	top: 0;
	background-color: #000;
	color: #fff;
	border-bottom: 1px solid rgb(123, 123, 123);
	white-space: break-spaces;
}

h1,
h2,
h3 {
	text-align: center;
}

.transactions {
	position: relative;
	width: 90%;
	margin: 0 auto 30px;
	border-collapse: separate;
	text-align: center;
	background-color: #d7d7d7;
	box-shadow: 0px 6px 10px 0px rgb(0 0 0 / 50%);
	box-sizing: border-box;
	border: 1px #000 solid;
  	border-spacing: 0 0.7em;
}

.transactions th {
	background-color: black;
	color: white;
	padding: 8px 2px;
	width: 50%;
}

.transactions tr {
	transition: background-color 0.3s;
}

.transactions tbody:nth-child(1n)  {
	background-color: gray;
	color: #6305C9;
}

.transactions tbody:nth-child(2n) {
	background-color: #333;
	color: #fff;
}

button.btn {
	display: block;
}

td::first-letter {
    text-transform: capitalize;
}

 tr td:first-of-type {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

 tr td:last-of-type {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}
tr {
  padding: 5px 5px;
  font-weight: bold;
}

.expandable-data {
	color: white;
}

@media (max-width:767px) and (min-width:521px)  {
	tbody {
		line-height: 4vh;
	}
	
	.nowrap-text {
		text-wrap: nowrap;
	}
}
</style>
